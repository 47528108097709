import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/common/Layout';
import Container from '../components/common/Container';
import Document from '../components/Document';
import Event from '../components/Event';
import Folder from '../components/Folder';
import NewsItem from '../components/NewsItem';
import { Wrapper } from '../components/landing/CallToAction/styles';

const componentFor = (data) => {
  const nodes = (query) =>
    (query ? query.edges : []).map((edge) => edge.node);
  if (data) {
    if (data.ploneDocument) {
      return (
        <Document
          data={data.ploneDocument}
          images={nodes(data.allPloneImage)}
          files={nodes(data.allPloneFile)}
        />
      );
    }
    if (data.ploneEvent) {
      return (
        <Event
          data={data.ploneEvent}
          images={nodes(data.allPloneImage)}
          files={nodes(data.allPloneFile)}
        />
      );
    }
    if (data.ploneFolder) {
      return (
        <Folder
          data={data.ploneFolder}
          images={nodes(data.allPloneImage)}
          files={nodes(data.allPloneFile)}
        />
      );
    }
    if (data.ploneCollection) {
      return (
        <Folder
          data={data.ploneCollection}
          images={nodes(data.allPloneImage)}
          files={nodes(data.allPloneFile)}
        />
      );
    }
    if (data.ploneNewsItem) {
      return (
        <NewsItem
          data={data.ploneNewsItem}
          images={nodes(data.allPloneImage)}
          files={nodes(data.allPloneFile)}
        />
      );
    }
    return null;
  }
  return null;
};

const DefaultLayout = ({ data }) => (
  <Layout>
    <Wrapper as={Container}>{componentFor(data)}</Wrapper>
  </Layout>
);

export default DefaultLayout;

export const query = graphql`
  query DefaultTemplateQuery($path: String!) {
    ploneDocument(_path: { eq: $path }) {
      ...Document
    }
    ploneEvent(_path: { eq: $path }) {
      ...Event
    }
    ploneFolder(_path: { eq: $path }) {
      ...Folder
    }
    ploneCollection(_path: { eq: $path }) {
      ...Collection
    }
    ploneNewsItem(_path: { eq: $path }) {
      ...NewsItem
    }
    allPloneFile(filter: { _backlinks: { eq: $path } }) {
      edges {
        node {
          ...File
        }
      }
    }
    allPloneImage(filter: { _backlinks: { eq: $path } }) {
      edges {
        node {
          ...Image
        }
      }
    }
    ploneBreadcrumbs(_path: { eq: $path }) {
      items {
        _id
        _path
        title
      }
    }
  }
`;
